<template>
  <div id="app" class="text-center text-gray-800 font-sans mt-4">
    <MainNavbar /> <!-- Navbar component should use Tailwind classes -->
    <router-view></router-view> <!-- Route views will be rendered here -->
  </div>
</template>

<script>
import MainNavbar from './components/MainNavbar.vue';

export default {
  name: 'App',
  components: {
    MainNavbar,
  },
};
</script>

<style scoped>
/* Remove custom styles to rely entirely on Tailwind for styling */
</style>
