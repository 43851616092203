<template>
  <div class="blog-page">
    <h1>Blog Page</h1>
    <AddBlogPost @postAdded="fetchPosts" />
    <BlogList :posts="posts" @refreshPosts="fetchPosts" />
  </div>
</template>

<script>
import AddBlogPost from '../components/AddBlogPost.vue'; // Correcting the import path
import BlogList from '../components/BlogList.vue'; // Correcting the import path
import { getBlogPosts } from '../services/api.js'; // Importing the getBlogPosts function from api.js

export default {
  components: {
    AddBlogPost,
    BlogList
  },
  data() {
    return {
      posts: []
    };
  },
  created() {
    this.fetchPosts();
  },
  methods: {
    fetchPosts() {
      // Using the getBlogPosts function to make an API call
      getBlogPosts()
        .then(response => {
          this.posts = response.data;
        })
        .catch(error => {
          console.error('Error fetching posts:', error);
        });
    }
  }
};
</script>

<style scoped>
.blog-page {
  padding: 20px;
}
</style>
