<template>
    <nav class="bg-primary-dark text-white p-4">
        <router-link to="/" class="hover:text-secondary-light mr-4">Home</router-link>
        <router-link to="/about" class="hover:text-secondary-light mr-4">About</router-link>
        <router-link to="/blog" class="hover:text-secondary-light">Blog</router-link>
    </nav>
</template>

<script>
export default {
    name: 'MainNavbar',
}
</script>

<style scoped>
/* Additional scoped styles can go here if necessary */
</style>
