import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './assets/styles/tailwind.css';

// Sentry setup for error logging
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

const app = createApp(App);

Sentry.init({
  app,
  dsn: 'YOUR_SENTRY_DSN', // Replace with your actual DSN from Sentry
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router), // Use directly from @sentry/vue
      tracingOrigins: ['localhost', /^\//], // Track routes
    }),
  ],
  tracesSampleRate: 1.0, // Adjust this value as needed
});

app.use(router).mount('#app');
