// src/services/api.js

import axios from 'axios';

// Directly specify the full URL for each API function

// Function to add a new blog post
export function addBlogPost(postData) {
  return axios.post('/api/blogposts', postData); // Replace with full URL
}

// Function to get all blog posts
export function getBlogPosts() {
  return axios.get('/api/blogposts'); // Replace with full URL
}

// Other API functions can be added here
