<template>
    <div class="p-6 bg-neutral-light min-h-screen">
        <h1 class="text-4xl font-bold text-primary mb-6">Welcome to Expected Maximum</h1>
        <p class="text-lg text-neutral-dark">
            This is your personal website's homepage. Use the navbar to navigate through the site.
        </p>
        <AdComponent
      ad-client="ca-pub-1234567890" 
      ad-slot="9876543210" 
      ad-format="auto"
      ad-style="display:block; text-align:center;" />
    </div>
</template>

<script>
import AdComponent from '@/components/AdComponent.vue';

export default {
    components: {
        AdComponent
    },
    name: 'HomePage',
}
</script>

<style scoped>
.home-page {
    padding: 20px;
}
</style>
