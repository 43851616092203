<template>
  <div class="ad-container">
    <!-- Google AdSense Ad -->
    <ins class="adsbygoogle"
         :style="adStyle"
         :data-ad-client="adClient"
         :data-ad-slot="adSlot"
         :data-ad-format="adFormat"></ins>
  </div>
</template>

<script>
export default {
  props: {
    adClient: {
      type: String,
      required: true,
      default: 'ca-pub-1234567890' // Replace this with your actual AdSense publisher ID
    },
    adSlot: {
      type: String,
      required: true,
      default: '1234567890' // Replace this with your actual ad slot ID
    },
    adFormat: {
      type: String,
      default: 'auto', // You can customize this (e.g., 'rectangle', 'horizontal', etc.)
    },
    adStyle: {
      type: String,
      default: 'display:block', // Default style for the ad
    }
  },
  mounted() {
    this.loadAd();
  },
  methods: {
    loadAd() {
      // Ensure the adsbygoogle array exists
      if (window.adsbygoogle) {
        try {
          (window.adsbygoogle = window.adsbygoogle || []).push({});
        } catch (e) {
          console.error('AdSense error:', e);
        }
      }
    }
  }
}
</script>

<style scoped>
.ad-container {
  margin: 20px 0;
  text-align: center;
}
</style>
